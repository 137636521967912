<template>
  <div class="signature flex">
    <div class="flex">
      <div id="signature" ></div>
      <div class="footer flex">
        <van-button type="info" color="#3F7C53" @click="save" id="sign_ok">
          <span>保</span><span>存</span><span>签</span><span>名</span>
        </van-button>
        <van-button @click="clear" class="clear-btn" id="sign_clear"><span>重</span><span>写</span></van-button>
      </div>
    </div>
    <!-- <div class="desc">
       <span>注</span>
       <span>：</span>
       <span>请</span>
       <span>在</span>
       <span>框</span>
       <span>内</span>
       <span>书</span>
       <span>写</span>
       <span>签</span>
       <span>名</span>
    </div> -->
    <!-- <div>注：请在框内书写签名</div> -->
    <!-- <img :src="imgsrc" alt=""> -->

  </div>
</template>

<script>
import { Button, Toast } from 'vant'
import { saveFileInfo, saveSignFile, joinApply } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  data() {
    return {
      imgsrc: '',
      tempToken: ''
    }
  },
  methods: {
    clear() {
      $("#signature").jSignature("reset")
    },
    callback(base64data) {
      if(this.$route.query.redirect == 'joinck'){
        this.tosaveSignFile(base64data)
      }else{
        this.tosaveFileInfo(base64data)
      }  
    },
    tojoinApply() {
       joinApply({
          token: this.tempToken,
          userName: common.getItem('userNameCK'),
          idCard: common.getItem('idCardCK'),
          agreementNo: 'AGT000'
        }).then(res=>{
          if(res.code == 200){
            if(res.data && res.data.businessCode == 200){
              localStorage.removeItem('idCardCK')
              localStorage.removeItem('userNameCK')
              this.$router.push({name:'joincksuccess'})
            }else{
              this.$toast(res.data.businessMessage)
            }
          }
        })
    },
    tosaveSignFile(base64data){
      saveSignFile({
        fileType: 'perSign',
        stationNo: this.$route.query.stationNo,
        token: this.tempToken,
        picOwnNo: common.getItem('phoneNo'), //  游客为申请加盟入口，传手机号
        fileContent: base64data,
        idCard: common.getItem('idCardCK')
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
           this.tojoinApply() 
          }
        }
      })
    },
    tosaveFileInfo(base64data){
      saveFileInfo({
        fileType: 'perSign',
        stationNo: this.$route.query.stationNo,
        token: this.tempToken,
        picOwnNo: common.getItem('masterNo') ? common.getItem('masterNo') : this.$route.query.masterNo, // 是站长是传masterNo；
        fileContent: base64data,
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            common.setItem('signZZ', true)
            this.$router.push({name:'sitedetail', query:{
              stationNo:this.$route.query.stationNo,
              name: this.$route.query.redirect,
              partner: this.$route.query.partner}})
          }
        }
      })
    },
    save() {
      var datapair = $("#signature").jSignature("getData", "image")
      var array = datapair.splice(",")
      // data:image/png;base64,
      // console.log("data:" + array[0] + "," + array[1])
      let imgSrc= "data:" + array[0] + "," + array[1]
      let _this = this
      common.rotateBase64Img(imgSrc, 90, (base64)=>{
        _this.callback(base64)
      })
    },
  },
  mounted() {
    this.tempToken = this.$route.query.tokenapp ? this.$route.query.tokenapp : common.getItem('wtToken')
    $(document).ready(function() {
      let params = {
        width: '6.5rem',
        height: document.body.clientHeight + 'px',
        lineWidth: '3'
      };
      $("#signature").jSignature(params)
    });
    uwStatisticAction('/site/signature','站长签约合作框架协议')
  }
}
</script>

<style lang="less" scoped>
.signature{
  height: 100%;
  background: #f5f5f5;
  flex-direction: column;
  .flex{
    display: flex;
  }
  .desc{
    flex:1;
    height: 100%;
    text-align: center;
    span{
      display: inline-block;
      transform: rotate(-90deg);
    }
  }
  .content{
    height: 80%;
  }
  .sign_show{
    width: 2rem;
    height: 1rem;
    border: 0.01rem solid #666;
  }
  .footer{
    flex-direction: column;
    .clear-btn{
      background: #999;
      color: #fff;
    }
    .van-button{
      width: 100%;
      flex:1;
      .van-button__text{
        transform: rotate(-90deg) translateY(-0.35rem);
        display: inline-block;
      }
    }
  }
}
</style>
